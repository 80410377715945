import './Footers.css'

export const FooterHome = () => {
  return (
    <div className="footer-home">
      <div className="footer-title">
        © 2025 Dariel Guerra
      </div>
      {/*Dariel Guerra 2025 © */}
    </div>
  )
}